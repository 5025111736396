<template>
  <section article-slider v-editable="blok" :data-style="blok?.style || 'default'" :id="sectionId" :data-spacing="blok?.spacing || 'default'">
    <div class="container">
      <div class="row">
        <label class="section-header" v-if="blok?.header?.length > 0" v-text="blok.header"></label>
        <h2 v-if="blok?.title && title != ''" v-html="title" class="section-title"></h2>
        <div v-if="blok?.description && description != ''" v-html="description" class="section-description"></div>
        <div class="section-articles">
          <template v-for="article in blok.articles" :key="article.id">
            <ArticleCard v-if="article?.id" :article="article" :data-card="blok?.card_style || 'default'"/>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/article-slider.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });
const title = computed(() => useRichText(props?.blok?.title || {}));
const description = computed(() => useRichText(props?.blok?.description || {}));
const sectionId = computed(() => {
    if(props.blok?.section) return textSlugify(props.blok?.section);
    return props.blok?._uid;
})
</script>
